import React from "react";
import '../../App.css'
import { Link } from "react-router-dom";
import CallSpeciesPhoto from "../../components/CallSpeciesPhoto";
import VideoGal from "../../components/VideoGal";

function OtherSpecies() {
    return (
        <div>
            <div className="pageHeaderPic">
                <img className="pageHeaderPic"
                    src="https://firebasestorage.googleapis.com/v0/b/task-manager-58200.appspot.com/o/pagepics%2Frabbits.png?alt=media&token=54c68ce9-5490-406c-aa11-33290e617631"
                />
                <div className="legendStyle">
                    <h1>What other wildlife do we have? </h1>
                </div>
            </div>
            <div className= "frontpagetext" >
            <div className='frontpagetext-inner' >
                <h1> 
                    Reptiles
                </h1>
                <div className="small_gap" />
                <p> 
                    The UK has two main species of snake - adders and grass snakes (we also have smooth snakes, however these are 
                    incredibly rare). Adders are the only venemous snake in the UK, they are usually found in woodlands and we have 
                    never seen any on the farm. Grass snakes on the other hand, are completely harmless and quite shy. We often find them
                    hidden away in dark, damp areas. They tend to 
                    slither quickly away whenever we find one. They feed primarily on fish and amphibians so our frog and toad 
                    populations are very useful to them. We have even seen them swimming across the lakes while hunting for fish!
                </p>
                <div className="medium_gap" />
                <h1> 
                    Amphibians
                </h1>
                <div className="small_gap" />
                <p>
                    The UK has seven native amphibian species: two frogs, two toads, and three newts. The frogs are common and pool. 
                    The toads are common and natterjack. And the newts are great crested, smooth, and palmate. We have a variety of these 
                    on the farm, but not all of them. 
                </p>
                <div className="small_gap" />
                <p>
                    Great crested newts, while not present on the farm are present in our local area. Some of the woodland and ponds 
                    across the Thames from us acts as their habitat. In fact the woodland at the bottom of Wittenham Clumps has more 
                    great crested newts than almost anywhere else in the UK. Great crested newts are protected in the UK as they are under 
                    threat from habitat depletion and pollution. Due to their protected nature, we have carried out surveys to ensure 
                    that they are not present and our work is not affecting them. 
                </p>
                <div className="medium_gap" />
                <h1> 
                    Fish
                </h1>
                <div className="small_gap" />
                <p> 
                    Our lake has been filled with trout for the birds to feed on and for us to fish. Occasionally you may see trout on the 
                    cafe menu - these were likely caught that day by our chef! Some of our trout grow very large, they can reach up to 18 kg. 
                    Their diet consists of smaller fish and their eggs, as well as insects on the water. 
                </p>
                <div className="medium_gap" />
                <h1> 
                    Other mammals
                </h1>
                <div className="small_gap" />
                <p>
                    Whilst our most common mammals are badgers, rabbits, foxes, and deer; we also occasionally have visits from other 
                    species such as stoats and otters. We don't believe any otters live on the farm, but they occasionally come 
                    by when roaming the Thames to forage for some food. 
                </p>
                <div className="medium_gap" />
                <div className="small_gap" />
                <p>
                    We even managed to get a little bit of footage of some visiting otters!
                </p>
                <div className="small_gap" />
                <VideoGal species='otter' />
                <h1> 
                    Want to learn more?
                </h1>
                <div className="small_gap" />
                <p> 
                    To continue learning about our different wildlife please continue with the links below:
                </p>
            </div>
            </div>
            <div className="trapLinks">
                <div className="imageContainer">
                <Link to="/wildlife/badgers" onClick={() => {window.scroll(0, 0);}}>
                    <CallSpeciesPhoto name='badger_redirect' species='' width='178px' />
                <div className="bannersmall">
                    <h4>Badgers</h4>
                </div>
                </Link>
                </div>
                <div className="imageContainer">
                <Link to="/wildlife/foxes" onClick={() => {window.scroll(0, 0);}}>
                    <CallSpeciesPhoto name='fox_redirect' species='' width='178px' />
                <div className="bannersmall">
                    <h4>Foxes</h4>
                </div>
                </Link>
                </div>
                <div className="imageContainer">
                <Link to="/wildlife/deer" onClick={() => {window.scroll(0, 0);}}>
                    <CallSpeciesPhoto name='deer_redirect' species='' width='178px' />
                <div className="bannersmall">
                    <h4>Deer</h4>
                </div>
                </Link>
                </div>
                <div className="imageContainer">
                <Link to="/wildlife/rabbits" onClick={() => {window.scroll(0, 0);}}>
                    <CallSpeciesPhoto name='rabbit_redirect' species='' width='178px' />
                <div className="bannersmall">
                    <h4>Rabbits</h4>
                </div>
                </Link>
                </div>
                <div className="imageContainer">
                <Link to="/wildlife/birds" onClick={() => {window.scroll(0, 0);}}>
                    <CallSpeciesPhoto name='birds_redirect' species='' width='178px' />
                <div className="bannersmall">
                    <h4>Birds</h4>
                </div>
                </Link>
                </div>
            </div> 
        </div>
    );
}

export default OtherSpecies;